import React, { useState, useEffect } from "react";
import "../styles/Navbar.css";
import { FaBars, FaTimes } from "react-icons/fa";
import { FaCartShopping } from "react-icons/fa6";
import { Link } from "react-router-dom";
import icon from "../assets/images/kannur.png";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isShrink, setIsShrink] = useState(false);
  const [category, setCategory] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const cartss = localStorage.getItem("CartLength") || 0;

  const handleMenuToggle = () => {
    setIsMobile(!isMobile);
  };

  const handleScroll = () => {
    setIsShrink(window.scrollY > 50);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    fetchCategoryList();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const fetchCategoryList = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:6013/kdpetroleum/list/category",
        {},
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      setCategory(response.data.list);
      console.log(response.data.list);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      <nav className={`kp-navbar ${isShrink ? "kp-navbar-shrink" : ""}`}>
        <div className="kp-navbar-logo">
          <img src={icon} alt="Logo" className="kp-logo-image" />
          <span className="kp-logo-text">
            <Link to="/">Kannur Petroleum</Link>
          </span>
        </div>
        <div>
          <ul
            className={`kp-navbar-links ${
              isMobile ? "kp-navbar-links-mobile kp-active" : ""
            }`}
          >
            <li>
              <a href="/" onClick={() => setIsMobile(false)}>
                Home
              </a>
            </li>
            <li>
              <a href="/Product" onClick={() => setIsMobile(false)}>
                Product
              </a>
            </li>
            <li
              className={`kp-dropdown ${
                isDropdownOpen ? "kp-dropdown-open" : ""
              }`}
            >
              <a
                href="#"
                onClick={(e) => {
                  if (isMobile) {
                    e.preventDefault();
                    handleDropdownToggle();
                  }
                }}
              >
                Category
              </a>
              <ul className="kp-dropdown-menu">
                {category.map((item) => (
                  <li key={item.cg_id}>
                    <a href={`/Category/${item.cg_id}`}>
                      <span className="kp-dropdown-icon">➤</span>
                      {item.cg_name}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <a href="/services" onClick={() => setIsMobile(false)}>
                Services
              </a>
            </li>
            <li>
              <a href="/about" onClick={() => setIsMobile(false)}>
                About
              </a>
            </li>
            <li>
              <a href="/contact" onClick={() => setIsMobile(false)}>
                Contact
              </a>
            </li>
            <li>
              <a
                href="/cart"
                onClick={() => setIsMobile(false)}
                className="kp-cart-link"
              >
                <div className="kp-cart-badge">
                  {cartss == "undefined" ? "0" : cartss}
                </div>
                <FaCartShopping className="kp-cart-icon" />
              </a>
            </li>
          </ul>
        </div>
        <div className="kp-navbar-icons" onClick={handleMenuToggle}>
          {isMobile ? (
            <FaTimes className="kp-menu-icon kp-close-icon" />
          ) : (
            <FaBars className="kp-menu-icon" />
          )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
