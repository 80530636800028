import React from "react";
import "../styles/Services.css";
import { useNavigate } from "react-router-dom";

const services = [
  {
    title: "Battery Water Supply",
    description:
      "Reliable and high-quality distilled water for automotive and industrial battery maintenance.",
    icon: "🔋",
  },
  {
    title: "Fire Extinguisher Refilling",
    description:
      "Professional refilling services to ensure fire extinguishers are ready for emergency use at all times.",
    icon: "🔥",
  },
  {
    title: "Solar Power",
    description:
      "Efficient solar power solutions, including installation and maintenance for sustainable energy needs.",
    icon: "☀️",
  },
  {
    title: "Cash Counting Machines Sales and Service",
    description:
      "Sales and servicing of cash counting machines to ensure fast and accurate financial transactions.",
    icon: "💵",
  },
  {
    title: "Insurance",
    description:
      "Comprehensive insurance services to protect assets and ensure financial security.",
    icon: "🛡️",
  },
  {
    title: "Accounts and Audit Work",
    description:
      "Ensure financial accuracy and compliance with regulatory standards through professional auditing services.",
    icon: "📊",
  },
  {
    title: "Online Services of OMCs",
    description:
      "Streamlined access to online services for Oil Marketing Companies, enhancing operational efficiency.",
    icon: "🌐",
  },
  {
    title: "Vehicle  for Legal Metrology Stamping ",
    description:
      "Authorised vehicle available for Annual Verification services of Legal Metrology .",
    icon: "🚛",
  },
  {
    title: "Tanker Chase & Body",
    description:
      "High-quality tanker chase and bodywork services to meet industry safety and performance standards.",
    icon: "🚚",
  },
  {
    title:
      "Financial Support - from Collaborated Banks and Other Financial Institutions (EDFS Model)",
    description:
      "Tailored financial support services in collaboration with banks and institutions for business growth.",
    icon: "🏦",
  },
  {
    title: "Garden Work",
    description:
      "Enhance and maintain retail outlet aesthetics with professional gardening and landscaping services.",
    icon: "🌿",
    highlighted: true,
  },
  {
    title: "RO Fabrication and Painting ",
    description:
      "B-Site Canopy Fabrication and maintenance, Illumination and Painting works by experts",
    icon: "🎨",
  },
];

const Services = () => {
  const navigate = useNavigate();

  const handleEnquiry = (title) => {
    navigate(`/enquiry?service=${encodeURIComponent(title)}`);
  };
  return (
    <div className="services-section">
      <h2>Our Services</h2>
      <p className="services-description">
        Comprehensive solutions tailored to enhance safety, efficiency, and
        reliability in petroleum operations.
      </p>

      <div className="services-grid">
        {services?.map((service, index) => (
          <div
            key={index}
            className={`service-card ${
              service.highlighted ? "highlighted" : ""
            }`}
          >
            <div className="service-icon">{service.icon}</div>
            <h3>{service.title}</h3>
            <p>{service.description}</p>
            <a
              className="read-more"
              onClick={() => handleEnquiry(service.title)}
            >
              Enquiry now
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
