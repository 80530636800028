import React, { useState, useEffect } from "react";
import "../styles/CartList.css";
import { useNavigate } from "react-router-dom";

const Cart = () => {
  const Navigate = useNavigate();
  const [cartItems, setCartItems] = useState(
    JSON.parse(localStorage.getItem("cartItems"))
  );

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    localStorage.setItem("CartLength", cartItems?.length);
  }, [cartItems]);

  const updateQuantity = (p_id, amount) => {
    setCartItems((prevItems) =>
      prevItems.map((item) => {
        if (item.p_id === p_id) {
          const newQuantity = item.quantity + amount;

          return {
            ...item,
            quantity: Math.max(1, Math.min(newQuantity, item.p_stocks)),
          };
        }
        return item;
      })
    );
  };
  const updateSize = (productId, selectedSize) => {
    setCartItems((prevCartItems) =>
      prevCartItems.map((item) =>
        item.p_id === productId ? { ...item, selectedSize } : item
      )
    );
  };

  const removeItem = (p_id) => {
    setCartItems((prevItems) => prevItems.filter((item) => item.p_id !== p_id));
  };

  const calculateTotal = () => {
    return cartItems?.reduce(
      (total, item) => total + item.p_price * item.quantity,
      0
    );
  };

  const baseUrl = "https://lunarsenterprises.com:6013/";
  return (
    <div style={{ marginTop: "70px", marginBottom: "50px" }}>
      <div className="cart-container">
        <h2>Your cart</h2>
        <div className="cart-header">
          <span>PRODUCT</span>
          <span>QUANTITY</span>
          <span>TOTAL</span>
        </div>
        {cartItems?.length > 0 ? (
          cartItems?.map((item) => {
            const sizes = item.p_description?.match(/\d+/g)?.map(Number);
            return (
              <div className="cart-item" key={item.p_id}>
                <div className="product-details">
                  <img src={baseUrl + item.p_image} alt={item.p_name} />
                  <div className="product-info">
                    <h3>{item.p_name}</h3>
                    <p>INR. {item.p_price}.00</p>
                    {/* <p>{item.p_description}</p> */}
                    {sizes && sizes.length > 0 && (
                      <div className="size-selection-container">
                        <label
                          htmlFor={`size-${item.p_id}`}
                          className="size-label"
                        >
                          Select Size:
                        </label>
                        <select
                          id={`size-${item.p_id}`}
                          className="size-dropdown"
                          value={item.selectedSize || ""}
                          onChange={(e) =>
                            updateSize(item.p_id, e.target.value)
                          }
                        >
                          <option value="" disabled>
                            Select size
                          </option>
                          {sizes.map((size) => (
                            <option key={size} value={size}>
                              {size}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                </div>
                <div className="quantity-controls">
                  <button onClick={() => updateQuantity(item.p_id, -1)}>
                    -
                  </button>
                  <input type="text" value={item.quantity} readOnly />
                  <button onClick={() => updateQuantity(item.p_id, 1)}>
                    +
                  </button>
                  <button
                    className="delete-button"
                    onClick={() => removeItem(item.p_id)}
                  >
                    🗑️
                  </button>
                </div>
                <div className="total-price">
                  INR {item.p_price * item.quantity}.00
                </div>
              </div>
            );
          })
        ) : (
          <p>Your cart is empty.</p>
        )}
        <div className="cart-footer">
          <p>Estimated total INR {calculateTotal()}.00</p>
          <p>Taxes, Discounts and shipping calculated at checkout</p>
          <button
            disabled={cartItems?.length ? false : true}
            className="checkout-button"
            onClick={() => Navigate("/CheckOut")}
          >
            Check Out
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cart;
