import React, { useEffect, useState } from "react";
import "./Home.css";
import building from "../../assets/images/main-building_upscaled.jpeg";
import building1 from "../../assets/images/main_building_upscaled.jpeg";
import buiding3 from "../../assets/images/another-side.jpg";

const images = [building, building1, buiding3];

const Home = () => {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="home-container"
      style={{ backgroundImage: `url(${images[currentImage]})` }}
    >
      {/* <div className="hero-section">
        <div className="hero-content">
          <h1>We are Happily Saying </h1>
          <h1>We are Awesome</h1>
          <p>
            Dictum voluptatibus, erat pharetra scelerisque, nisl faucibus eu
          </p>
          <p>mus, blandit at suscipit. Eu a. A vel hymenaeos.</p>
          <div className="hero-buttons">
            <button className="btn-primary">Contact Us</button>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Home;
