import React, { useEffect, useState } from "react";
import "../styles/Team.css";
import axios from "axios";

const baseUrl = "https://lunarsenterprises.com:6013/";

const Team = () => {
  const [team, setTeam] = useState([]);

  useEffect(() => {
    TeamList();
  }, []);

  const TeamList = () => {
    axios({
      url: "https://lunarsenterprises.com:6013/kdpetroleum/list/team-member",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        setTeam(data.data.list);
        console.log(data.data.list);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  return (
    <section className="team-section">
      <h2 className="team-title">Our Pillar of Guidance</h2>
      {/* <p className="team-description">
        Meet the professionals who drive our mission forward with expertise and
        dedication. Their commitment ensures our success and growth in every
        endeavor.
      </p> */}

      <div className="team-grid">
        {team
          ?.filter((member) =>
            ["Chairman", "Secretary", "Treasurer"].includes(
              member.t_designation
            )
          )
          ?.map((member, index) => (
            <div key={index} className="team-member">
              <img
                src={`${baseUrl}${member.t_image}`}
                alt={member.t_name}
                className="team-image"
              />
              <h3 className="member-name">{member.t_name}</h3>
              <p className="member-role">{member.t_designation}</p>
            </div>
          ))}
      </div>
    </section>
  );
};

export default Team;
