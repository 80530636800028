import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/EnquiryForm.css";
import { toast } from "react-toastify";
import axios from "axios";

const Enquiry = () => {
  const navigate = useNavigate();
  const [pumpName, setPumpName] = useState("");
  const [place, setPlace] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const serviceTitle = queryParams.get("service") || "Enquiry";

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!pumpName || !place || !phone || !message) {
      toast.error("Please fill in all fields.");
      return;
    }

    try {
      const response = await axios({
        url: "https://lunarsenterprises.com:6013/kdpetroleum/service-enquiry",
        method: "POST",
        data: {
          name: pumpName,
          pump_name: pumpName,
          place: place,
          phone: phone,
          service: serviceTitle,
          message: message,
        },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (response.data.status === true) {
        toast.success(
          response.data.message || "Enquiry submitted successfully!"
        );
        navigate("/");
      } else {
        toast.error(response.data.message || "Failed to submit the enquiry.");
      }
    } catch (error) {
      console.error("Error submitting the enquiry:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="enquiry-form-container">
      <h2>{serviceTitle}</h2>
      <form className="enquiry-form" onSubmit={handleFormSubmit}>
        <div className="form-group">
          <label htmlFor="pumpName">Pump Name</label>
          <input
            type="text"
            id="pumpName"
            value={pumpName}
            onChange={(e) => setPumpName(e.target.value)}
            placeholder="Enter your Pump Name"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="place">Place</label>
          <input
            type="text"
            id="place"
            value={place}
            onChange={(e) => setPlace(e.target.value)}
            placeholder="Enter your Place"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="number"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Enter your Phone Number"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            rows="4"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Enter your message"
            required
          ></textarea>
        </div>
        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default Enquiry;
